import { Box, Paper, Typography, LinearProgress } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuestionNum from "./QuestionNum";
import ProctorTest from "./ProctorTest";

function TestSideBar({ children }) {
  const answer = useSelector((state) => state.tests.answers);
  const test = useSelector((state) => state.tests);
  const percentage = useSelector((state) => state.tests.percentage);
  // console.log('testtt',test)
  return (
    <Box
      width="100vw"
      height="89vh"
      sx={{
        boxSizing: "border-box",
        display: "flex",
      }}
    >
      <Box
        width="25%"
        height="100%"
        bgcolor="white"
        sx={{
          boxSizing: "border-box",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        {/* <Box height="15%" p={3}>
          <Typography variant="h7">Your Progress</Typography>
          <Typography pb={3} variant="h6" fontWeight={800}>
            {percentage}% to complete
          </Typography>
          <LinearProgress
            variant="determinate"
            value={percentage}
            color="primary"
            sx={{ borderRadius: 8 }}
          />
        </Box> */}
        <Box sx={{ height: "40%", p: 3 }}>
          <Typography variant="h6" sx={{ fontWeight: 800, mb: 1 }}>
            Overview
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 0.5,
              pl: 1,
              height: "98%",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "0.5em",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "primary.main",
                borderRadius: 7,
              },
            }}
          >
            {answer.map((ans, idx) => {
              return <QuestionNum key={idx} num={idx + 1} answer={ans.answer} />;
            })}
          </Box>
        </Box>
        { test && test.aiproctor && <Box height="44%">
          <ProctorTest inputWidth={"100%"} />
        </Box>}
      </Box>
      <Box width="75%" minHeight="100%" bgcolor="primary.lighter">
        {children}
      </Box>
    </Box>
  );
}

export default TestSideBar;
