import {
  faAt,
  faEye,
  faEyeSlash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Box,
  Button,
  Divider,
  InputBase,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import Authservice from "../../store/services/auth.service";
import { useNavigate } from "react-router-dom";

import auth from "../../store/actions/auth";
import authService from "../../store/services/auth.service";
import { useEffect } from "react";

const style = {
  width: { xs: "70%", sm: "40%", md: "30%", xl: "35%" },
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#B0C8DC",
  border: "2px solid #004E8F",
  borderRadius: "10px",
  p: 4,
};

function Signup(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    password: "",
    re_password: "",
    otp: "",
  });
  const [remainingTime, setRemainingTime] = useState(60);
  const [loadingResendOTP, setLoadingResendOTP] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const [verifyotploading, setverifyotpLoading] = useState(false);
  const [otpmodal, setotpModal] = useState(false);

  const [otperror, setotpError] = useState({
    isError: false,
    errorMessage: "",
  });

  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [verifyloading, setVerifyLoading] = useState(false);
  const [vissibleRePass, setVissibleRePass] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [rePassError, setrePassError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [vissible, setVissible] = useState(false);
  const [loading, setLoading] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/;

  const [modal, setModal] = useState(false);

  useEffect(() => {
    setRemainingTime(60);
    startTimer();
  }, [otpmodal]);

  const openModal = () => {
    setModal(true);
    setInputs((prevState) => ({
      ...prevState,
      otp: "",
    }));
  };
  const closeotpModal = () => setotpModal(false);

  function handleOnChange(e) {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }
  const validate = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^[a-zA-Z0-9!@^()_+\\[\]\\|<>]*$/;
    const nameRegex = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/; // Only letters with single spaces between words
  
    // Trim unnecessary spaces in the name field
    inputs.name = inputs.name.trim();
  
    if (!inputs.email) {
      setError({
        isError: true,
        errorMessage: "Email field cannot be empty. Please enter your email address.",
      });
      return false;
    }
  
    if (!emailRegex.test(inputs.email)) {
      setError({
        isError: true,
        errorMessage: "Invalid email format. Example: user@example.com",
      });
      return false;
    }
  
    if (!inputs.password) {
      setError({
        isError: true,
        errorMessage: "Password field cannot be empty. Please enter a password.",
      });
      return false;
    }
  
    if (inputs.password.length < 8) {
      setError({
        isError: true,
        errorMessage: "Password must be at least 8 characters long.",
      });
      return false;
    }
  
    if (!passwordRegex.test(inputs.password)) {
      setError({
        isError: true,
        errorMessage: "Password contains invalid characters. Use only letters, numbers, and !@^()_+[]|<>.",
      });
      return false;
    }
  
    if (!inputs.name) {
      setError({
        isError: true,
        errorMessage: "Name field cannot be empty. Please enter your name.",
      });
      return false;
    }
  
    if (!nameRegex.test(inputs.name)) {
      setError({
        isError: true,
        errorMessage: "Name must contain only letters and single spaces between words. Example: John Doe",
      });
      return false;
    }
  
    return true;
  };
  

  const handleResendOtp = async () => {
    try {
      setLoadingResendOTP(true);
      const res = await authService.resendOTP(inputs.email);
      // console.log(res);
      setRemainingTime(60);
      startTimer();
      // if (res.status === 403) {
      //   setOtpBlockModal(true);
      //   setLoadingResendOTP(false);
      //   setRemainingTime(10);
      //   setTimeout(() => {
      //     setOtpBlockModal(false);
      //   }, 3000);
      //   return;
      // }
      setLoadingResendOTP(false);
    } catch (error) {
      setLoadingResendOTP(false);
      console.log(error);
    }
  };

  const startTimer = () => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
          return 0;
        }
        if (!otpmodal) {
          clearInterval(timer);

          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setotpError([false, ""]);
    setVerifyLoading(true);
    if (inputs.otp.length > 6) {
      setVerifyLoading(false);
      setotpError([true, "OTP should not be greater than 6 digits"]);
      return;
    }
    if (inputs.otp.length === 0) {
      setVerifyLoading(false);
      setotpError([true, "Enter OTP"]);
      return;
    }
    try {
      const response = await Authservice.verifyotp(inputs.email, inputs.otp);
      if (localStorage.getItem("token")) {
        setVerifyLoading(false);
        closeotpModal();
        setotpError([false, "success"]);
        alert("Otp Verfired Please Login");
        navigate("/login");
      } else {
        setVerifyLoading(false);
        setotpError([false, "success"]);
        alert("Otp Verfired Please Login");
        navigate("/login");
      }
      setotpError([false, ""]);
    } catch (error) {
      setVerifyLoading(false);
      setotpError([true, "Error Wrong Otp or User already verified"]);
    }
  };

  async function handleSignup(e) {
    e.preventDefault();
    setotpError([false, ""]);
    setEmailError(false);
    setPasswordError(false);
    setrePassError(false);
    setNameError(false);
    setError({
      isError: false,
      errorMessage: "",
    });

    if (
      emailRegex.test(inputs.email) &&
      inputs.password.length >= 8 &&
      inputs.password === inputs.re_password &&
      inputs.name.length > 0
    ) {
      try {
        if (!validate()) {
          return;
        }
        setLoading(true);
        localStorage.setItem("email", inputs.email);
        await authService.signup(
          inputs.name,
          "user",
          inputs.email,
          inputs.password
        );
        setLoading(false);
        setError({
          isError: false,
          errorMessage: "",
        });
        setotpModal(true);
      } catch (error) {
        setLoading(false);
        setError(
          error.data
            ? {
                isError: true,
                errorMessage: error.data.error,
              }
            : {
                isError: true,
                errorMessage: error.message,
              }
        );
      }
    } else {
      setEmailError(!emailRegex.test(inputs.email));
      setPasswordError(!(inputs.password.length >= 8));
      setrePassError(!(inputs.password === inputs.re_password));
      setNameError(!(inputs.name.length > 0));
    }
  }

  return (
    <Box sx={{ mt: 5, position: "relative" }}>
      {(emailError ||
        passwordError ||
        rePassError ||
        nameError ||
        error.isError) && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {nameError
            ? "Name cannot be blank"
            : emailError
            ? "Invalid Email Address"
            : passwordError
            ? "Password must be at least 8 characters"
            : rePassError
            ? "Password does not match"
            : error.errorMessage}
        </Alert>
      )}
      <Typography fontWeight={800} sx={{ mb: 1 }}>
        Full Name
      </Typography>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputBase
          id="name"
          sx={{ ml: 1, flex: 1 }}
          placeholder="Enter Your Full Name"
          inputProps={{ "aria-label": "search google maps" }}
          value={inputs.name}
          onChange={handleOnChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSignup(e);
            }
          }}
        />
      </Paper>

      <Typography fontWeight={800} sx={{ mb: 1, mt: 2, userSelect: "none" }}>
        Email
      </Typography>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputBase
          id="email"
          sx={{ ml: 1, flex: 1 }}
          placeholder="Enter Your Email"
          value={inputs.email}
          onChange={handleOnChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSignup(e);
            }
          }}
        />
        <FontAwesomeIcon color="#004E8F" icon={faAt} />
      </Paper>

      <Typography fontWeight={800} sx={{ mb: 1, mt: 2, userSelect: "none" }}>
        Password
      </Typography>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputBase
          id="password"
          sx={{ ml: 1, flex: 1 }}
          placeholder="Enter Your Password"
          type={vissible ? "text" : "password"}
          value={inputs.password}
          onChange={handleOnChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSignup(e);
            }
          }}
        />
        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            setVissible((prevState) => !prevState);
          }}
          color="#004E8F"
          icon={vissible ? faEye : faEyeSlash}
        />
      </Paper>

      <Typography fontWeight={800} sx={{ mb: 1, mt: 2, userSelect: "none" }}>
        Re-Enter Password
      </Typography>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputBase
          id="re_password"
          sx={{ ml: 1, flex: 1 }}
          placeholder="Re-Enter Your Password"
          type={vissibleRePass ? "text" : "password"}
          value={inputs.re_password}
          onChange={handleOnChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSignup(e);
            }
          }}
        />
        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            setVissibleRePass((prevState) => !prevState);
          }}
          color="#004E8F"
          icon={vissibleRePass ? faEye : faEyeSlash}
        />
      </Paper>

      <Button
        variant="contained"
        sx={{ width: "100%", mt: 8, ml: 0.5, mb: 2 }}
        color="primary"
        onClick={handleSignup}
        disabled={loading}
      >
        {loading ? "Signing in" : "Sign up"}
        {loading && (
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            style={{ marginLeft: "8px" }}
          />
        )}
      </Button>
      <Modal
        open={otpmodal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            style={{
              marginBottom: "10px",
              textAlign: "left",
              lineHeight: "1.25",
              fontWeight: "bold",
            }}
          >
            Verify your Email first to login, OTP has been sent to your email.
            If you didn't receive please check Spam in your mail.
            <br />
            <div style={{ position: "absolute", top: "5px", right: "5px" }}>
              {Math.floor(remainingTime / 60)}:
              {remainingTime % 60 < 10 ? "0" : ""}
              {remainingTime % 60}
            </div>
          </Typography>
          <Paper
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <InputBase
              id="otp"
              sx={{ ml: 1, flex: 1 }}
              placeholder="Enter Your OTP"
              value={inputs.otp}
              onChange={handleOnChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit(e);
                }
              }}
            />
          </Paper>
          <Button
            variant="contained"
            sx={{ width: "100%", mt: 5, ml: 0.5, mb: 2 }}
            color="primary"
            onClick={handleSubmit}
            disabled={verifyotploading}
            // id="verify"
          >
            {verifyotploading ? "" : "Verify OTP"}
            {verifyotploading && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                style={{ marginLeft: "8px" }}
              />
            )}
          </Button>
          <Button
            onClick={closeotpModal}
            disabled={verifyotploading || otperror[1] === "success"}
            variant="contained"
            color="error"
            type="submit"
          >
            {/* <FontAwesomeIcon icon={faBackward} /> */}
            Cancel
          </Button>
          <Button
            sx={{ marginLeft: "10px", height: "40px", width: "120px" }}
            onClick={handleResendOtp}
            disabled={remainingTime > 0}
            variant="contained"
            color="primary"
            type="submit"
          >
            {/* <FontAwesomeIcon icon={faBackward} /> */}
            {loadingResendOTP ? (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                style={{ marginLeft: "8px" }}
              />
            ) : (
              "Resend OTP"
            )}
          </Button>
        </Box>
      </Modal>
      <Divider>OR</Divider>
      <Button
        variant="contained"
        sx={{ width: "100%", mt: 2, ml: 0.5, mb: 2 }}
        color="secondary"
        onClick={() => {
          navigate("/login");
        }}
        disabled={loading}
      >
        Log in
      </Button>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(auth.login(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
