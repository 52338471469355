import { iparhaiServer } from "../store/services/config/api.config";

let mediaRecorder = null; // Global variable for stopRecording

// Helper function to start the recording
const startRecording = async (questionId) => {
  console.log("log on start Recording", questionId);

  // Capture the current questionId in a local variable.
  const currentQuestionId = questionId;
  const userId = localStorage.getItem("userId");

  try {
    // Request audio permissions and create a recorder.
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorder = new MediaRecorder(stream);

    let audioChunks = []; // Local audio chunks array

    mediaRecorder.start();

    mediaRecorder.ondataavailable = (event) => {
      audioChunks.push(event.data);
    };

    mediaRecorder.onstop = async () => {
      const audioBlob = new Blob(audioChunks, { type: "audio/wav" });

      // Logging the current questionId before API call
      console.log("log before API call", currentQuestionId);

      // Pass the correct questionId to uploadAudio
      await uploadAudio(audioBlob, userId, currentQuestionId);

      // Clear local audio chunks
      audioChunks = [];
    };
  } catch (error) {
    console.error("Error starting recording:", error);
  }
};

// Helper function to stop the recording
const stopRecording = () => {
  if (mediaRecorder && mediaRecorder.state === "recording") {
    console.log("Stopped recording");
    mediaRecorder.stop();
  }
};

// Helper function to upload the audio
const uploadAudio = async (audioBlob, userId, questionId) => {
  const testId = localStorage.getItem("tid");
  const formData = new FormData();
  formData.append("audio", audioBlob, `audio-${Date.now()}.wav`);
  formData.append("userId", userId);
  formData.append("testId", testId);
  formData.append("questionId", questionId);

  try {
    const response = await iparhaiServer.post(
      "/api/company/uploadAudio",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.error("Error uploading audio:", error);
  }
};

export default {
  startRecording,
  stopRecording,
};
